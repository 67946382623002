(function () {
  'use strict';

  /*jshint sub:true*/
  /*jshint camelcase: false */

angular
    .module('com.module.users')
    .config(function ($stateProvider) {
      $stateProvider
        .state('app.users', {
          url: '/users',
          resolve:{
            admin:function(AppAuth,currentUser){
                return AppAuth.isAdmin(currentUser);
            }
          },
          template: '<section admin-header class="content-header" title="Users" sub-title="Manage your users here!"></section><section class="content"><users></users></section>'
        })
    })
    .component('users', {
        templateUrl: 'app/modules/users/users-component/template.html',
        bindings: {
        },
        controllerAs: 'vm',
        controller: function($uibModal, UService, SchemaMaker, GridHelper,CoreService,AuthProvidersService,AuthProviderConnectionsService,CONFIG,UserService,$state,$timeout,SystemUser, LoopBackAuth) {
            var vm = this;
            
            vm.availableAuthProviders = [];
            AuthProvidersService.find().then(function(res) { 
                vm.availableAuthProviders = res.data;
            });
            

            // init config
            vm.config={
                controllerAs:'vm'
            };
            vm.closeForm = function(){
                vm.formOpen=!vm.formOpen;
                $state.reload();
            };
            
            /* List */
            var schema = UService.gridSchema;
            vm.pagination = {
                pageNumber: 1,
                pageSize: 15,
                sort: null
            };
            vm.filters = {skip:0,limit:vm.pagination.pageSize};
            vm.loadListData = function(filters) {
                filters = filters || vm.filters;
                // console.log("Req Filter", filters);
                // when you search make sure you dont skip records
                // if (filters.where && Object.keys(filters.where).length > 0) {
                //     vm.pagination.pageNumber = 1;
                //     filters.skip = 0;
                // }
              
                vm.promise = UService.get(filters).then(function(res) {
                    var headers = res.headers();
                    vm.gridOptions.totalItems = Number(headers['x-total-count']);
                    vm.gridOptions.data = res.data; //postProcess(res.data);
                });
                return vm.promise;
            };
            vm.gridOptions = GridHelper.gridOptions(schema, vm.loadListData, vm.pagination, vm.filters,vm.config);
            vm.loadListData();
            
            vm.deleteAll = function() {
                if (vm.filters.where) {
                    CoreService.confirm('Are you sure?', 'Deleting ' + vm.gridOptions.totalItems + ' users!',
                        function(success) {
                            UService.get({ where: vm.filters.where })
                                .then(function(res) {
                                    return res.data.map(function(u) { return u.id });
                                })
                                .then(function(ids) {
                                    // console.log(ids);
                                    // return { data: { count: ids.length } };
                                    return UService.deleteBulk(ids);
                                })
                                .then(function(res) {
                                    CoreService.toastSuccess(res.data.count + ' users deleted!');
                                    vm.loadListData();
                                })
                                .catch(function(err) {
                                    console.error(err);
                                    CoreService.toastError(err.error.message);
                                });
                        },
                        function(cancel) {}
                    );
                }
            };

            /* Single */
            
            vm.item = {};
            vm.formSchema = UService.getFormSchema();
            vm.viewElement = CoreService.viewElement;
            vm.env = CoreService.env;
            
            window.tempSessions = window.tempSessions || {};
            vm.createSessionUrl = function(token){
                return vm.env.apiUrl + '/SystemUsers/'+ vm.item.id +'/setTempSession?access_token=' + token;
            };
            vm.createTempSession = function(){
                UService.createTempSession(vm.item.id)
                    .then(function(res){
                        vm.session = {
                            url : vm.createSessionUrl(res.data.id),
                            token:res.data.id,
                            ttl: res.data.ttl/3600
                        };
                        window.tempSessions[vm.item.id] = Object.assign({},vm.session);
                        getSessions();
                    });
            }
            vm.createElement = function(item){
                // CoreService.alertInfo("Cannot create User from here", "Users should only be invited to projects.");
                // console.log("Create",item);
                // vm.formOpen = !vm.formOpen;
                // vm.item={};
            };
            vm.resetPassword = function(email){
                SystemUser.resetPassword({email:email}).$promise.then(function(result){
                    CoreService.toastSuccess('Password reset requested!','Please check your email: '+email);
                }).catch(CoreService.error);
            };
            vm.getUpdatePasswordUrl = function(userId){
                var root = CoreService.env.apiUrl;
                var token = LoopBackAuth.accessTokenId;
                var url = root+'/SystemUsers/'+vm.item.id+'/getUpdatePasswordUrl?access_token='+token;
                return url;                
            };
            function getSessions(){
               vm.currentSessions = [];
               UService.userSessions(vm.item.id).then(function(res) {
                   console.log("Current session tokens:", res.data);
                   vm.currentSessions = res.data;
                   vm.session = window.tempSessions[vm.item.id];
               });
            }
            function getSSOData(){
                vm.currentSSOConnections = [];
                AuthProviderConnectionsService.getUserConnections(vm.item.id).then(function(res) {
                    vm.currentSSOConnections = res;
                });
            }
            vm.editElement = function(item){
                vm.currentUserSeats = 0;
                console.log("Edit",item);
                vm.formOpen = !vm.formOpen;
                vm.item=item;
                vm.item.roles = item.systemRoles.map(function(r){return Object.assign({},r,vm.getRole(r.name));});
                getSessions();
                getSSOData();
                if(vm.hasRole('creouser')) {
                    UService.getUserSeats(vm.item.id).then(function(account){
                        vm.currentUserSeats = account.seats;
                    });
                }
            };
            vm.deleteElement = function(item){
                console.log("Delete",item);     
                CoreService.confirm('Are you sure?','Deleting this cannot be undone',
                  function () {
                    //   confirm
                    vm.promise = UService.delete(item).then(function () {
                        CoreService.success('Deleted successfully',(item.name||item.id)+' deleted!');
                        vm.loadListData();
                    }).catch(CoreService.error);
                  },
                  function () {
                    //   cancel
                  }
                );
            };
            vm.updateUserSeats = function(seats) {
                var userId = vm.item.id;
                if(!vm.hasRole('creouser')) return;
                if (!seats || seats < 0) return CoreService.error({data:{error:{
                    status:400,
                    name:"Invalid Seats",
                    message:"Seats must be greater than 0"
                }}});
                console.log("vm.currentUserSeats",seats);
                return UService.updateUserSeats(userId, seats)
                    .then(function(res) {
                        CoreService.success('Seats saved successfully');
                    }).catch(CoreService.error);

            }
            vm.submit = function(){
                var item = vm.item;
                vm.updateUserSeats(vm.currentUserSeats);
                vm.promise = UService.save(item)
                    .then(function(res){
                        CoreService.success('Saved successfully',res.data.id);
                        vm.formOpen = !vm.formOpen;
                        vm.item=null;
                        vm.loadListData();
                    }).catch(CoreService.error);
            };
            
            /* Special Roles Methods */
            vm.hasRole = function(role){
                if(!vm.item.id) return;
                return vm.item.roles.filter(function(r){
                    return role===r.value;
                }).length>0
            }
            vm.systemRoles = CONFIG.SystemRoles;
            vm.getRole = function(role){
                return vm.systemRoles.filter(function(r){
                    return (r.value===role||r.id===role);
                })[0];
            };
            vm.deleteRole = function(user,role){
              vm.promise = UserService.deleteSystemRole(user,role).then(function(){
                user.roles = user.roles.filter(function(r){ return r.id!==role.id; });
              });
            };
            vm.insertRole = function(user,role){
              var exist=user.roles.map(function(role){return role.name}).indexOf(role.value)>=0;
              if(!exist) vm.promise = UserService.insertSystemRole(user,role).then(function(){
                vm.item.roles.push(role);
              });
            };
            
            /* Special SSO Auth Provider methods */
            vm.addAuthProviderConnection = function(c) {
                var found;
                vm.currentSSOConnections.forEach(function(p){
                    if(c.label===p.label) {
                        found = true;
                    }
                })
                if(found) {
                    CoreService.toastWarning(c.label+' already enabled!','This SSO provider is already enabled for this user!');
                    return;
                }
                AuthProviderConnectionsService.addUserConnection(vm.item.id,vm.item.email,c.id).then(function(){
                    getSSOData();
                })
            }
            vm.deleteAuthProviderConnection = function(c) {
                AuthProviderConnectionsService.deleteUserConnection(vm.item.id,c.id,function(){
                    getSSOData();
                })
            }
            
            
        }

    });
    
    
})();